<template>
    <div class="container">
        <p class="head">我的团队</p>
        <div class="team-body">
            <ul class="list-join">
                <dt class="font-600">加入的团队</dt>
                <span v-if="!isShow" class="temp">暂无</span>
                <div v-if="isShow" >
                    <li v-for="(item,index) in join" :key="index" class="lists">
                        <div class="content">
                            <img :src="item.src" @click="goToDetails(item.teamID)" />
                            <el-tooltip :content="item.title" placement="bottom" effect="light">
                                <span @click="goToDetails(item.teamID)" >{{item.title}}</span>
                            </el-tooltip>
                        </div>
                    </li>
                </div>
            </ul>
            <ul class="list-found">
                <dt class="font-600">创建的团队</dt>
                <span v-if="!isShow2" class="temp">暂无</span>
                <div v-if="isShow2">
                    <li v-for="(item,index) in found" :key="index" class="lists">
                        <div class="content">
                            <img :src="item.src" @click="goToDetails(item.teamID)" />
                            <el-tooltip :content="item.title" placement="bottom" effect="light">
                                <span @click="goToDetails(item.teamID)" >{{item.title}}</span>
                            </el-tooltip>
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</template>
<script>
import {QUERY} from '@/services/dao.js';
export default {
    data(){
        return{
            isShow: true,
            isShow2:true,
            // 加入的团队
            join:[
                // {
                //     src:'https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3448936851,681826596&fm=26&gp=0.jpg',
                //     title: "在线答疑天团"
                //     teamID:
                // },
                // {
                //     src:'https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3118956596,1809570833&fm=26&gp=0.jpg',
                //     title: "在线智囊指南"
                // }
            ],
            // 创建的团队
            found:[
                // {
                //     src:'https://dss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2158809544,1978664305&fm=26&gp=0.jpg',
                //     title: "繁星star"
                // },
                // {
                //     src:'https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2747364614,2411895227&fm=26&gp=0.jpg',
                //     title: "浩瀚心空"
                // }
            ]
        }
    },
    created(){
        this.init();
    },
    methods:{
        init(){
            this.initJoin();
            this.initFound();
        },
        // 我加入的团队
        async initJoin(){
            let user_id = this.$store.state.userinfo.id;
            let data = await QUERY("POST","",'WDTeamJoined(where:{member:{_eq:'+user_id+'}}) {t_id teamName teamImg}');
            // console.log("joined: ",data);
            if(data.data.WDTeamJoined.length>0){
                for(let i=0;i<data.data.WDTeamJoined.length;i++){
                    this.join.push({
                        src:this.api.LoginURL.concat(data.data.WDTeamJoined[i].teamImg),
                        title:data.data.WDTeamJoined[i].teamName,
                        teamID:data.data.WDTeamJoined[i].t_id,
                    })
                }
                this.isShow = true;
            }else{this.isShow = false;}
        },
        // 我创建的团队
        async initFound(){
            let user_id = this.$store.state.userinfo.id;
            let data = await QUERY("POST","",'WDTeamFound(where:{member:{_eq:'+user_id+'}}) {t_id teamName teamImg}');
            // console.log("found: ",data);
            if(data.data.WDTeamFound.length>0){
                for(let i=0;i<data.data.WDTeamFound.length;i++){
                    this.found.push({
                        src:this.api.LoginURL.concat(data.data.WDTeamFound[i].teamImg),
                        title:data.data.WDTeamFound[i].teamName,
                        teamID:data.data.WDTeamFound[i].t_id,
                    })
                }
                this.isShow2 = true;
            }else{this.isShow2 = false;}
        },
        // 跳转到团队详情
        goToDetails(id){
            console.log("id",id)
            this.$router.push({path:'/team-members',query:{id}})
        }
    }
}
</script>
<style scoped>
.container{
  /* padding: 15px; */
}
.head{
  font-size:14px;
  font-family:Microsoft YaHei;
  font-weight:400;
  color:rgba(3,3,3,1);
  /* line-height:58px; */
  font-weight: 800;
  padding-bottom: 15px;
}
.team-body{
    /* padding: 20px; */
    border:solid 0.007353rem #e8e8e8;
}
.font-600{
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 15px;
}
.content{
    width: 68px;
    /* height: 85px; */
    margin-right: 15px;
    text-align: center;
}
.list-join::after,.list-found::after{
    display: block;
    content: '';
    clear: both;
}
.lists{
    float: left;
}
.content img{
    border-radius: 50%;
    width: 67px;
    height: 67px;
    cursor: pointer;
}
.content span {
    display: inline-block;
    margin-top: 5px;
    width:58px;
    height: 14px;
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:300;
    color:rgba(3,3,4,1);
    line-height:14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    cursor: pointer;
}
.temp{
    color: #e1e1e1;
    font-family: fantasy;
    font-size: 14px;
}
</style>